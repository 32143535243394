import { useRef, useMemo, ReactNode, useContext } from 'react'
import { Tabs, Tab, Box, styledComponent, styled, WhiteTooltip } from '@hermes/web-components'

import useStickyElementOnScroll from '../../hooks/useStickyElementOnScroll'
import usePageScrollProgress from '../../hooks/usePageScrollProgress'
import useTranslateMessage from '../../hooks/useTranslateMessage'
import useActiveTabTracker from '../../hooks/useActiveTabTracker'
import { AppDataContext } from '../../providers/AppData'
import ContentTopContainer from '../ContentTopContainer'
import { ProfileTabsProps } from '../../types/index'
import { useVisibilityComponents } from '../../hooks/useVisibilityComponents'
import ArrowRightIcon from '../Icons/ArrowRightIcon'

const TabsRoot = styledComponent(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.blue[200]}`,
  position: 'relative',
  overflowX: 'auto',
  '& .MuiTab-root': {
    '&:after': {
      display: 'none !important'
    }
  },
  [theme.breakpoints.down('lg')]: {
    position: 'sticky',
    top: 64,
    left: 0,
    marginTop: 0,
    zIndex: 999
  },
  [theme.breakpoints.down('sm')]: {
    top: 48,
    borderTop: `1px solid ${theme.palette.blue[200]}`
  }
}))

const StyledTab = styledComponent(Tab, { shouldForwardProp: (prop: string) => prop !== 'active' })<{
  active: boolean
  component: string
  href: string
}>(({ active, theme }) => ({
  color: theme.palette.text.darkNavy,
  px: 0,
  mr: 3,
  fontSize: 15,
  fontWeight: 400,
  opacity: 1,
  flexWrap: 'nowrap',
  minWidth: 'max-content',
  ...(active && {
    color: theme.palette.primary.dark,
    fontWeight: 600,
    '&:after': {
      // eslint-disable-next-line quotes
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      background: '',
      width: '100%',
      borderRadius: 4,
      height: 4,
      left: 0
    }
  }),
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.body2.fontSize
  },
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

const ScrollIndicator = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'width'
})<{ width: number }>(({ width, theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: 4,
  width: `${width}%`,
  background: theme.palette.system.navy,
  borderRadius: 4,
  [theme.breakpoints.down('sm')]: {
    height: 1,
    background: theme.palette.text.darkNavy900
  }
}))

function ProfileTabs({ specialist, practice, isUnclaimed = false }: ProfileTabsProps) {
  const { locale, isRtl } = useContext(AppDataContext)

  const translate = useTranslateMessage()
  const visibilityComponents = useVisibilityComponents({
    specialist,
    practice
  })

  const tabs = useMemo(() => {
    const profileTabs: { label: string | ReactNode; selector: string }[] = []

    if (visibilityComponents.about) {
      profileTabs.push({ label: translate('about'), selector: 'about' })
    }

    if (specialist) {
      if (visibilityComponents.fees) {
        profileTabs.push({ label: translate('fees', { defaultMessage: 'Fees' }), selector: 'fees' })
      }
      if (visibilityComponents.location) {
        profileTabs.push({
          label: translate('locations'),
          selector: 'locations'
        })
      }
      if (visibilityComponents.reviews) {
        profileTabs.push({
          label: translate('reviews.reviews'),
          selector: 'reviews'
        })
      }
      if (visibilityComponents.peerRecommendations) {
        profileTabs.push({
          label: translate('skills'),
          selector: 'endorsements'
        })
      }
    }
    if (practice) {
      if (visibilityComponents.location) {
        profileTabs.push({
          label: translate('working_hours'),
          selector: 'locations'
        })
      }
      if (visibilityComponents.reviews) {
        profileTabs.push({
          label: translate('reviews.reviews'),
          selector: 'reviews'
        })
      }
      if (visibilityComponents.specialists) {
        profileTabs.push({
          label: translate('specialists'),
          selector: 'specialists'
        })
      }
      if (visibilityComponents.facilities || visibilityComponents.services || visibilityComponents.parking) {
        profileTabs.push({
          label: translate('facilities'),
          selector: 'services-facilities'
        })
      }
      if (visibilityComponents.gallery) {
        profileTabs.push({
          label: translate('gallery'),
          selector: 'gallery'
        })
      }
    }

    if (visibilityComponents.videos) {
      profileTabs.push({
        label: (
          <WhiteTooltip isRtl={isRtl} title={translate('media-tooltip.text')}>
            <Box
              sx={{
                borderRadius: '50px',
                background:
                  'linear-gradient(90deg, rgba(0, 163, 255, 0.20) 0%, rgba(0, 229, 208, 0.20) 99.99%, rgba(0, 229, 208, 0.20) 100%)',
                border: '1.5px solid #00A3FF',
                padding: '2px 10px'
              }}
            >
              {translate('media_center.videos')}
            </Box>
          </WhiteTooltip>
        ),
        selector: 'media'
      })
    }

    if (visibilityComponents.articles) {
      profileTabs.push({
        label: translate('media_center.articles'),
        selector: 'articles'
      })
    }

    if (visibilityComponents.faq) {
      profileTabs.push({
        label: translate('faq'),
        selector: 'faq'
      })
    }

    return profileTabs
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialist, isUnclaimed, practice])

  const trackingTabs = useMemo(() => {
    if (visibilityComponents.about) {
      return [...tabs, { label: translate('about'), selector: 'about' }]
    }
    return tabs
  }, [tabs, translate, visibilityComponents.about])

  const getTabIndex = (id: string) => {
    if (tabs.findIndex((el) => el.selector === id) === -1) {
      return false
    }
    return tabs.findIndex((el) => el.selector === id)
  }

  const activeElement = useActiveTabTracker(trackingTabs)
  const slug = (specialist && specialist.slug) || (practice && practice.slug)
  const isSpecialist = !!specialist
  const navRoot = useRef<HTMLDivElement>()
  const scrollProgress = usePageScrollProgress()
  const stickyOptions = useRef({ isDesktopOnly: true }).current
  useStickyElementOnScroll(navRoot, stickyOptions)

  const onClick = (tabLabel: string) => () => {
    window.dataLayer.push({ event: 'prf_pg_navigaton', prf_navigation_label: tabLabel })
  }

  return (
    <TabsRoot ref={navRoot}>
      <Tabs
        sx={{
          backgroundColor: 'transparent',
          '& .MuiTabs-scrollButtons': {
            height: '34px',
            width: '34px',
            borderRadius: '50px',
            border: '1px solid #D5DEF9',
            backgroundColor: 'transparent',
            padding: '8px'
          }
        }}
        value={false}
        component={ContentTopContainer}
        maxWidth="xl"
        ScrollButtonComponent={(props) => (
          <ArrowRightIcon
            directionLeft={props.direction === 'left'}
            style={{
              display: props.disabled ? 'none' : 'flex',
              boxShadow: `${props.direction === 'left' ? '4px -4px' : '-4px 0px'} 10px 0px #D5DEF94D`
            }}
            {...props}
          />
        )}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {tabs.map((tab) => (
          <StyledTab
            active={tab.selector === activeElement}
            key={tab.selector}
            component="a"
            href={`/${locale}/${isSpecialist ? 'specialist' : 'practice'}/${slug}#${tab.selector}`}
            label={tab.label}
            value={getTabIndex(tab.selector)}
            onClick={onClick(tab.selector)}
          />
        ))}
      </Tabs>
      <ScrollIndicator width={scrollProgress} />
    </TabsRoot>
  )
}

export default ProfileTabs
