import { createContext, useState, useEffect, PropsWithChildren, useMemo } from 'react'
import { useRouter, NextRouter } from 'next/router'
import { transformToQuery, parseQueryFromString } from '../../utils/query'
import getConfigVariable from '../../utils/getConfigVariable'
import { limitReviews, limitReviewsOnReviewPage } from '../../constants/profile'
import { isHashString, transformToHash } from '../../utils/hash'
import { getHashFromString, isActualFilters } from '../../utils/review'
import { SearchReviewsProviderProps, SearchReviewsFilters, SearchReviewsState } from './types'
import apiRequest from '../../api/request'

const SearchReviewsContext = createContext<SearchReviewsState>({
  state: {
    reviews: [],
    keywordNames: [],
    filters: {
      page: 1,
      sort: '',
      search: '',
      keyword: '',
      rating: [],
      days: 0,
      replies: 0
    },
    percentageAlt: { empty: { empty: 1 } },
    initiallyEmpty: true,
    isLoading: false,
    totalCount: 0,
    canLoadNext: true,
    isInitial: false,
    initialTotalCount: 0
  },
  changePage: (page) => page,
  changeSort: (sort) => sort,
  changeSearch: (search) => search,
  changeKeyword: (keyword) => keyword,
  changeRating: (item) => item,
  changeDate: (item) => item,
  changeReply: (item) => item
})

const getPaginationNumber = (paginationParam: string | string[] | undefined): number => {
  const page = Number(String(paginationParam)?.replace('page-', ''))
  return Number.isNaN(page) ? 1 : page
}

const getDefaultStateFromRouter = (
  router: NextRouter,
  defaultValues?: Record<string, number | string | number[]>
): SearchReviewsFilters => {
  const path = router.asPath
  const { search = '', sort = '', keyword = '' } = parseQueryFromString(path)
  return {
    page: Number(defaultValues?.page || getPaginationNumber(router.query.page) || 1),
    search: defaultValues?.search.toString() || search,
    rating: (defaultValues?.rating || []) as number[],
    days: (defaultValues?.days || 0) as number,
    replies: (defaultValues?.replies || 0) as number,
    sort: (defaultValues?.sort || sort) as SearchReviewsFilters['sort'],
    keyword: (defaultValues?.keyword || keyword) as SearchReviewsFilters['keyword']
  }
}

function SearchReviewsProvider({
  children,
  reviews: initialReviews,
  isPractice = false,
  profileId,
  totalCount: initialTotalCount,
  defaultFilters,
  keywordNames,
  currentLocation
}: PropsWithChildren<SearchReviewsProviderProps>) {
  const apiUrl = getConfigVariable('BASIC_API_URL')
  const router = useRouter()
  const isHash = isHashString(router.asPath)
  const defaultState = getDefaultStateFromRouter(router, defaultFilters)
  const isActualStateFilters = isActualFilters(router.asPath, defaultState)
  const isReviewPage = useMemo(() => router.pathname?.includes('reviews'), [router.pathname])
  const limitPerPage = limitReviews
  const limitReviewsOnPage = useMemo(
    () => (isReviewPage ? limitReviewsOnReviewPage : limitPerPage),
    [isReviewPage, limitPerPage]
  )
  const [state, setState] = useState<SearchReviewsState['state']>({
    reviews: initialReviews || [],
    totalCount: initialTotalCount || 0,
    filters: defaultState,
    isLoading: false,
    keywordNames,
    isInitial: isHash && !isActualStateFilters,
    initiallyEmpty: !initialTotalCount,
    canLoadNext: initialTotalCount > limitReviewsOnPage,
    initialTotalCount,
    currentLocation,
    percentageAlt: { empty: { empty: 1 } }
  })

  const generateQuery = (values: SearchReviewsFilters) => {
    const defaultQuery: Record<string | number, string | null | number[] | number | []> = {
      sort: values.sort,
      search: values.search,
      keyword: values.keyword,
      rating: values.rating || [],
      days: values.days || 0,
      replies: values.replies || 0
    }
    const filters: Record<string, number | string | number[]> = {
      ...defaultQuery,
      limit: limitPerPage,
      offset: Math.abs((Number(values?.page) - 1) * limitPerPage)
    }

    return {
      routerQuery: transformToHash(defaultQuery),
      apiQuery: transformToQuery(filters)
    }
  }

  useEffect(() => {
    if (initialReviews) {
      setState((prevState) => ({
        ...prevState,
        isLoading: isHash && state.isInitial,
        reviews: initialReviews,
        filters: { ...prevState.filters, page: prevState.filters?.page || 1 },
        isInitial: isHash && !isActualStateFilters
      }))
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialReviews])

  const parsingRating = (queryURL: string): number[] => {
    const query = queryURL.split('/').pop() || ''
    const params: { [key: string]: string | string[] } = query.split('&').reduce((acc, part) => {
      const [name, value] = part.split('=')

      if (name in acc) {
        if (!Array.isArray(acc[name])) {
          acc[name] = [acc[name] as string]
        }
        ;(acc[name] as string[]).push(value)
      } else {
        acc[name] = [value]
      }

      return acc
    }, {} as { [key: string]: string | string[] })

    if (params['rating[]'] && Array.isArray(params['rating[]'])) {
      return params['rating[]'].map((x) => +x)
    }
    return []
  }

  useEffect(() => {
    const apiBasePath = `${apiUrl}/${router.locale}/reviews/${
      isPractice ? '?practiceId=' : '?specialistId='
    }${profileId}`

    const loadReviewsFromApi = async () => {
      const parsedHash = isReviewPage && state.isInitial && getHashFromString(router.asPath)

      const { sort, keyword, search } = parsedHash || state.filters
      const { apiQuery, routerQuery } = generateQuery({
        sort: sort || 'desc',
        keyword,
        search,
        page: state.filters.page,
        rating: state.filters.rating,
        days: state.filters.days,
        replies: state.filters.replies
      })

      if (isReviewPage && !state.isInitial && router.isReady) {
        const url = `/${router.locale}${isPractice ? '/practice/' : '/specialist/'}[slug]/reviews/[page]${
          routerQuery ? `#${routerQuery}` : ''
        }`
        let as = `/${router.locale}${isPractice ? '/practice/' : '/specialist/'}${router.query.slug}/reviews`
        as += `/page-${state.filters.page}`
        if (routerQuery) {
          as += `#${routerQuery}`
        }
        router.push(url, as, { shallow: isReviewPage })
      }

      let apiUrl = apiBasePath
      if (apiQuery) {
        apiUrl += `&${apiQuery}`
      }
      const {
        result: reviews = [],
        totalCount = 0,
        percentage: percentageAlt = { empty: { empty: 1 } }
      } = await apiRequest(apiUrl)
        .then((res) => res.json())
        .then((res) => res || {})

      const shouldReplace = isReviewPage || state.filters.page === 1
      setState((prev) => ({
        ...prev,
        reviews: shouldReplace ? reviews : [...prev.reviews, ...reviews],
        totalCount,
        percentageAlt,
        filters: { ...prev.filters, ...parsedHash },
        isLoading: false,
        isInitial: false,
        canLoadNext: shouldReplace ? totalCount > reviews.length : totalCount > [...prev.reviews, ...reviews].length
      }))
    }

    const loadKeywordsNameFromApi = async () => {
      const { keywordNames: initialKeywordNames = [] } = await apiRequest(apiBasePath)
        .then((res) => res.json())
        .then((res) => res || {})
      setState((prev) => ({ ...prev, keywordNames: initialKeywordNames }))
    }

    if (state.isLoading) {
      loadReviewsFromApi()

      if (!state.keywordNames.length) {
        loadKeywordsNameFromApi()
      }
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLoading])

  const changePage = (page: number) =>
    setState((prev) => ({ ...prev, isLoading: true, filters: { ...prev.filters, page } }))
  const changeSort = (sort: string) =>
    setState((prev) => ({
      ...prev,
      isLoading: true,
      filters: { ...prev.filters, sort: sort as SearchReviewsFilters['sort'], page: 1 }
    }))
  const changeSearch = (search: string) =>
    setState((prev) => ({
      ...prev,
      isLoading: true,
      filters: { ...prev.filters, search, page: 1 }
    }))
  const changeKeyword = (keyword: string) =>
    setState((prev) => ({
      ...prev,
      isLoading: true,
      filters: { ...prev.filters, keyword, page: 1 }
    }))

  const changeRating = (item: number) =>
    setState((prev) => ({
      ...prev,
      isLoading: true,
      filters: {
        ...prev.filters,
        rating: !prev.filters.rating.includes(item)
          ? [...prev.filters.rating, item]
          : prev.filters.rating.filter((x: number) => x !== item),
        page: 1
      }
    }))

  const changeDate = (item: number) => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
      filters: {
        ...prev.filters,
        days: item,
        page: 1
      }
    }))
  }

  const changeReply = (item: number) => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
      filters: {
        ...prev.filters,
        replies: item,
        page: 1
      }
    }))
  }

  useEffect(() => {
    const parsedRating = parsingRating(router.asPath)
    parsedRating.forEach((item) => changeRating(item))
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SearchReviewsContext.Provider
      value={{
        state,
        changePage,
        changeSort,
        changeSearch,
        changeRating,
        changeKeyword,
        changeDate,
        changeReply
      }}
    >
      {children}
    </SearchReviewsContext.Provider>
  )
}

export { SearchReviewsProvider, SearchReviewsContext }
